


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'ExtraOption' })
export default class OptionsCard extends Vue {
  @Prop({ required: true }) readonly title!: string;

  @Prop({ required: true }) readonly icon!: string;
}

